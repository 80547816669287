<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-card>
        <b-table
          :fields="fields"
          :items="rows"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          :filter="filter"
          no-local-sorting
          responsive
        >
          <template v-if="data.item.userIndex" slot="index" slot-scope="data"> {{ data.item.userIndex }}. </template>

          <template v-if="!(data.item.id === 'empty')" slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <template slot="text">
                <img v-if="data.item.avatar" class="user-avatar" :src="data.item.avatar" />
                <span
                  style="margin-right: 15px; width: 100px"
                  :class="{ bold: data.item.isBold }"
                  @click="expand(data.item)"
                >
                  {{ data.item.name || 'Не указан' }}
                  <span v-if="data.item.entity" style="opacity: 0.7">({{ data.item.entity.name.toLowerCase() }})</span>
                </span>
                <span v-if="data.item['_links']" v-for="(link, alias) in data.item['_links']"
                  >| <a :href="link.link" target="_blank">{{ link.name }}</a></span
                >
              </template>
            </expander>
          </template>

          <template v-if="!(data.item.id === 'empty')" v-for="date in months" :slot="'month_' + date" slot-scope="data">
            <template v-if="data.item.dates && data.item.dates[date] && data.item.dates[date].value">
              <template v-if="data.item.dates[date].link">
                <a target="_blank" :href="data.item.dates[date].link" :class="{ link: true, bold: data.item.isBold }">
                  <span :class="[{ bold: data.item.isBold }]">
                    <template v-if="!data.item.isPercent">
                      {{ data.item.dates[date].value | formatNumber('0,[00]') }}
                    </template>
                    <template v-else> {{ data.item.dates[date].value | formatNumberNORMAL(2, ',', '') }}% </template>
                  </span>
                </a>
              </template>
              <template v-else>
                <span :class="[{ bold: data.item.isBold }]">
                  <template v-if="!data.item.isPercent">
                    {{ data.item.dates[date].value | formatNumber('0,[00]') }}
                  </template>
                  <template v-else> {{ data.item.dates[date].value | formatNumberNORMAL(2, ',', '') }}% </template>
                </span>
              </template>
            </template>
            <template v-else>-</template>
          </template>

          <template v-if="!(data.item.id === 'empty') && data.item.ratio > 0" slot="ratio" slot-scope="data">
            <span :class="[{ bold: data.item.isBold }]">{{ data.item.ratio | formatNumberNORMAL(2, ',', '') }}%</span>
          </template>

          <template v-if="!(data.item.id === 'empty')" slot="total" slot-scope="data">
            <template v-if="data.item.link">
              <a target="_blank" :href="data.item.link || '#'" :class="{ link: true, bold: data.item.isBold }">
                <span :class="[{ bold: data.item.isBold }]">
                  <template v-if="!data.item.isPercent">
                    {{ data.item.total | formatNumber('0,[00]') }}
                  </template>
                  <template v-else> {{ data.item.total | formatNumberNORMAL(2, ',', '') }}% </template>
                </span>
              </a>
            </template>
            <template v-else>
              <span :class="[{ bold: data.item.isBold }]">
                <template v-if="!data.item.isPercent">
                  {{ data.item.total | formatNumber('0,[00]') }}
                </template>
                <template v-else> {{ data.item.total | formatNumberNORMAL(2, ',', '') }}% </template>
              </span>
            </template>
          </template>

          <template v-if="!(data.item.id === 'empty')" slot="average" slot-scope="data">
            <span :class="[{ bold: data.item.isBold }]">{{ data.item.average | formatNumber('0,[00]') }}</span>
          </template>
        </b-table>
      </b-card>
    </template>
  </list>
</template>

<script>
  import List from '../../../components/List'
  import Expander from '../../../components/Expander'
  import remove from 'lodash/remove'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  export default {
    name: 'report',
    components: {
      Expander,
      List,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        months: [],
        fields: [
          {
            key: 'index',
            label: '&nbsp;',
            class: 'text-center',
          },
          {
            key: 'name',
            label: '&nbsp;',
            class: 'text-left',
          },
          {
            key: 'ratio',
            label: 'Доля',
            class: 'text-center relative',
          },
          {
            key: 'average',
            label: 'Среднее',
            class: 'text-center relative',
          },
          {
            key: 'total',
            label: 'Всего',
            class: 'text-center relative',
          },
        ],
        filters: [
          {
            name: 'name',
            label: 'Быстрый поиск...',
          },
          {
            name: 'date',
            type: 'date',
            range: true,
          },
        ],
      }
    },
    methods: {
      resultHandler(result) {
        result.items = this.dataPrepare(result)

        this.months = result._months

        remove(this.fields, ch => {
          return ch.custom
        })

        result._months.forEach((month, key) => {
          let mName = moment(month).format('MMMM YY')

          this.fields.push({
            custom: true,
            key: 'month_' + month,
            label: mName,
            class: 'text-center relative',
          })
        })
      },
    },
    mounted() {},
    beforeRouteEnter(to, from, next) {
      next()
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .user-avatar {
      margin-right: 5px;
    }
    .bold {
      font-weight: 900;
    }

    .relative {
      position: relative;
    }

    .link {
      color: #20a8d8 !important;
      &:hover {
        color: #2196f3 !important;
      }
      cursor: pointer;
      line-height: 1;
      display: inline-block;
    }
  }
</style>
